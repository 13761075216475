<template>
  <div class="w-full max-h-screen bg-background flex flex-col">
    <page-header backTo="prospect-index" title="Edit Enquiry">
      <button
        @click="printCompanyReport()"
        type="button"
        class="flex items-center bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M6 9V2h12v7M6 18H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2"
          />
          <path d="M6 14h12v8H6z" />
        </svg>
        <span>Print Company Record</span>
      </button>
      <button
        @click="deleteProspect()"
        type="button"
        class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
          />
        </svg>
        <span>Delete</span>
      </button>
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div v-if="prospect" class="w-full bg-white p-5 rounded shadow-md">
        <h2 class="text-3xl mb-4">
          {{ prospect.name }}
          <button
            @click="openEditPanel"
            class="ml-4 text-secondary bg-white p-1 rounded border border-transparent hover:border-secondary active:border-secondary active:shadow-inner active:bg-gray-200 transition duration-100"
          >
            <svg
              class="stroke-current h-6 w-6"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
            </svg>
          </button>
        </h2>

        <div>
          <div
            v-if="primaryContact[0].firstname || primaryContact[0].lastname"
            class="flex items-center py-1"
          >
            <svg
              class="text-orange-700 stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
              <circle cx="12" cy="7" r="4" />
            </svg>
            <p
              v-if="primaryContact[0].job_title"
              class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2"
            >
              {{ primaryContact[0].job_title }}
            </p>
            <p class="rounded-lg px-2 py-1">
              <span v-if="primaryContact[0].firstname">{{
                primaryContact[0].firstname + " "
              }}</span>
              <span v-if="primaryContact[0].lastname">{{
                primaryContact[0].lastname
              }}</span>
            </p>
          </div>
          <div v-if="primaryContact[0].email" class="flex items-center py-1">
            <svg
              class="text-orange-700 stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path
                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
              />
              <path d="M22 6l-10 7L2 6" />
            </svg>
            <a
              class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
              :href="'mailto:' + primaryContact[0].email"
              >{{ primaryContact[0].email }}</a
            >
          </div>
          <div
            v-if="primaryContact[0].mobile || primaryContact[0].telephone"
            class="flex items-center py-1"
          >
            <svg
              class="text-orange-700 stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path
                d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
              />
            </svg>
            <a
              v-if="primaryContact[0].mobile"
              class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
              :href="'tel:' + primaryContact[0].mobile"
              >{{ primaryContact[0].mobile }}</a
            >
            <a
              v-if="primaryContact[0].telephone"
              class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
              :href="'tel:' + primaryContact[0].telephone"
              >{{ primaryContact[0].telephone }}</a
            >
          </div>
          <div class="flex items-center py-1">
            <svg
              class="text-orange-700 stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
              <circle cx="12" cy="10" r="3" />
            </svg>
            <p class="rounded-lg px-2 py-1">{{ prospectAddress }}</p>
          </div>
          <div v-if="prospect.website" class="flex items-center py-1">
            <svg
              class="text-orange-700 stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="10" />
              <path
                d="M2 12h20M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z"
              />
            </svg>
            <a
              class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
              :href="'//' + prospect.website"
              target="_blank"
              >{{ prospect.website }}</a
            >
          </div>
        </div>
      </div>
      <div v-if="prospect" class="w-full bg-white p-5 rounded shadow-md mt-6">
        <div class="w-full mb-6">
          <div class="flex -mx-2 mr-2">
            <button
              @click="currentTab = 'info'"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
              :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'info',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'info',
              }"
            >
              <span>Information</span>
            </button>
            <button
              @click="currentTab = 'contacts'"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
              :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'contacts',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'contacts',
              }"
            >
              <span>Contacts</span>
            </button>
            <button
              @click="currentTab = 'notes'"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
              :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'notes',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'notes',
              }"
            >
              <span>Notes</span>
            </button>
            <div class="relative">
              <button
                @click="currentTab = 'actions'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'actions',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'actions',
                }"
              >
                <span>Actions</span>
              </button>
              <div
                v-if="incompleteActions > 0"
                class="absolute -mt-2 top-0 right-0 bg-orange-400 rounded-full leading-none text-orange-900 h-5 w-5 text-sm font-bold flex justify-center items-center"
              >
                {{ incompleteActions }}
              </div>
            </div>
            <button
              @click="currentTab = 'attachments'"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
              :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'attachments',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'attachments',
              }"
            >
              <span>Attachments</span>
            </button>
          </div>
        </div>
        <div v-if="currentTab == 'info'">
          <Information :enquiry="prospect" />
        </div>
        <div v-if="currentTab == 'contacts'">
          <Contacts :contacts="prospect.contacts" :enquiryId="prospectId" />
        </div>
        <div v-if="currentTab == 'notes'">
          <Notes :notes="prospect.notes" :enquiryId="prospectId" />
        </div>
        <div v-if="currentTab == 'actions'">
          <Actions :actions="prospect.events" :enquiryId="prospectId" />
        </div>
        <div v-if="currentTab == 'attachments'">
          <Attachments :enquiry="prospect" />
        </div>
        <div v-if="error">
          <div
            class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
          >
            <!-- <h2
                class="text-lg font-bold"
            >{{ errorMsg || 'There was an error loading your product details' }}</h2>-->
            <p>
              Couldn't load prospect with ID {{ this.prospectId }}:
              {{ error.statusText }}
            </p>
          </div>
        </div>

        <div>
          <div v-if="viewState == 'Error'">
            <div
              class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
            >
              <p>
                Something went wrong loading your enquiry, please try again. If
                the problem persists, please contact your support team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>

    <portal to="overlay-outlet">
      <panel
        :showing="editDetailsPanelOpen"
        @close="editDetailsPanelOpen = false"
        title="Edit Enquiry Details"
      >
        <edit-prospect-details
          :prospectDetails="prospectDetails"
          @complete="closeEditPanel"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>

    <transition name="slide-bottom">
      <view-attachment
        v-if="viewingAttachmentOpen"
        :attachment="viewingAttachment"
        @close="viewingAttachmentOpen = false"
      />
    </transition>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Panel = () => import("@/components/Panel.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const EditProspectDetails = () =>
  import("@/components/SalesProspects/Edit/EditProspectDetails.vue");
const ViewAttachment = () =>
  import("@/components/SalesProspects/Edit/ViewAttachment.vue");

const Information = () =>
  import("@/components/SalesProspects/Edit/Information.vue");
const Contacts = () => import("@/components/SalesProspects/Edit/Contacts.vue");
const Notes = () => import("@/components/SalesProspects/Edit/Notes.vue");
const Attachments = () =>
  import("@/components/SalesProspects/Edit/Attachments.vue");
const Actions = () => import("@/components/SalesProspects/Edit/Actions.vue");

export default {
  name: "EditProspect",
  props: {
    prospectId: String,
    isCreateResult: Boolean,
  },
  components: {
    PageHeader,
    Panel,
    Spinner,
    Notification,
    ConfirmAlert,
    EditProspectDetails,
    ViewAttachment,
    Information,
    Contacts,
    Notes,
    Attachments,
    Actions,
  },
  data() {
    return {
      viewState: "Idle",
      editDetailsPanelOpen: false,
      currentTab: "info",
      prospectDetails: {},
      isBusy: false,
      prospect: null,
      prospectAddress: "",
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      viewingAttachment: {},
      viewingAttachmentOpen: false,
      error: null,
    };
  },
  computed: {
    primaryContact() {
      return this.$_.filter(
        this.prospect.contacts,
        (contact) => contact.is_primary
      );
    },
    incompleteActions() {
      return this.$_.filter(this.prospect.events, (event) => !event.is_complete)
        .length;
    },
  },
  async mounted() {
    this.viewState = "Loading";

    try {
      this.prospect = await this.SalesProspectService.getProspectById(
        this.prospectId
      );

      this.prospect.notes = this.prospect.notes.reverse();

      if (this.isCreateResult) {
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Enquiry Created",
                  close: onClose,
                },
              },
              `Enquiry from '${this.prospect.name}' was created`
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.computeAddress();

      this.viewState = "Idle";
    } catch (err) {
      console.error(err);
      this.error = err;
      this.viewState = "Error";
    }
  },
  methods: {
    openEditPanel() {
      let primaryContact = this.$_.find(
        this.prospect.contacts,
        (contact) => contact.is_primary == true
      );
      this.prospectDetails = {
        name: this.prospect.name,
        address1: this.prospect.address1,
        address2: this.prospect.address2,
        address3: this.prospect.address3,
        city: this.prospect.city,
        county: this.prospect.county,
        postcode: this.prospect.postcode,
        country: this.prospect.country,
        website: this.prospect.website,
      };
      this.editDetailsPanelOpen = true;
    },

    handleDeleteProspect: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.SalesProspectService.deleteProspect(
            this.prospect.prospect_id
          );
          this.$router.push({
            name: "prospect-index",
          });
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Success",
                    close: onClose,
                  },
                },
                `Enquiry from '${this.prospect.name}' was deleted`
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "Error occurred while updating info, please try again later"
              );
            },
            {
              position: "top-right",
            }
          );
        }
        this.isBusy = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteProspect: function() {
      this.confirmAlert = {
        resultFunction: this.handleDeleteProspect,
        message: `Are you sure you wish to delete this enquiry?`,
        showing: true,
        data: this.prospect,
      };
    },

    async closeEditPanel() {
      try {
        this.isBusy = true;
        let primaryContact = this.$_.find(
          this.prospect.contacts,
          (contact) => contact.is_primary == true
        );

        this.prospect.name = this.prospectDetails.name;
        this.prospect.address1 = this.prospectDetails.address1;
        this.prospect.address2 = this.prospectDetails.address2;
        this.prospect.address3 = this.prospectDetails.address3;
        this.prospect.city = this.prospectDetails.city;
        this.prospect.county = this.prospectDetails.county;
        this.prospect.postcode = this.prospectDetails.postcode;
        this.prospect.country = this.prospectDetails.country;
        this.prospect.website = this.prospectDetails.website;
        this.computeAddress();

        await this.SalesProspectService.updateProspect(
          this.prospect.prospect_id,
          this.prospectDetails
        );

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Update Successful",
                  close: onClose,
                },
              },
              `Details for '${this.prospect.name}' were updated`
            );
          },
          {
            position: "top-right",
          }
        );
        this.editDetailsPanelOpen = false;
      } catch (err) {
        console.error(err);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "Error occurred while updating info, please try again later"
            );
          },
          {
            position: "top-right",
          }
        );
        this.editDetailsPanelOpen = false;
      }

      this.isBusy = false;
    },
    computeAddress: function() {
      let addr1 = this.prospect.address1 ? `${this.prospect.address1}, ` : "";
      let addr2 = this.prospect.address2 ? `${this.prospect.address2}, ` : "";
      let addr3 = this.prospect.address3 ? `${this.prospect.address3}, ` : "";
      let addr4 = this.prospect.address4 ? `${this.prospect.address4}, ` : "";
      let city = this.prospect.city ? `${this.prospect.city}, ` : "";
      let county = this.prospect.county ? `${this.prospect.county}, ` : "";
      let postcode = this.prospect.postcode
        ? `${this.prospect.postcode}, `
        : "";
      let country = this.prospect.country ? `${this.prospect.country}` : "";

      this.prospectAddress = `${addr1}${addr2}${addr3}${addr4}${city}${county}${postcode}${country}`;
    },
    printCompanyReport: async function() {
      try {
        let response = await this.SalesProspectService.printCompanyReport(
          this.prospect.prospect_id
        );
        response.original_filename = response.filename;
        this.viewingAttachment = response;
        this.viewingAttachmentOpen = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
